import React, { useEffect } from 'react';
import theme from '../../../constants/themes';
import { Top } from '../../templates/Top';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from '@mui/styled-engine';
import { Map } from '../../molecules/Map';
import MugiLogo from '../../../assets/images/mugi_logo.png';
import BakuLogo from '../../../assets/images/BAKU_logo.png';
import * as Scroll from 'react-scroll';

type Props = {};

const Text = styled(Typography)`
  font-family: "${theme.fonts.primary}";
  color: ${theme.colors.blackText};
`;

const MenuContainer = styled(Box)`
  @media ${theme.breakpoints.pc} {
    display: flex;
    flex-direction: row;
  }
`;

const MugiMenuContainer = styled(Box)`
  background-color: ${theme.colors.bgPrimary};
  min-height: 377px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${theme.breakpoints.pc} {
    min-height: 842px;
    width: 50%;
  }
`;

const BakuMenuContainer = styled(Box)`
  background-color: ${theme.colors.blackText};
  min-height: 377px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${theme.breakpoints.pc} {
    min-height: 842px;
    width: 50%;
  }
`;

const MenuImageWrapper = styled(Box)`
  height: 140px;
  width: 140px;
  border: solid 1px white;
  padding: 5px;

  margin-bottom: 26px;

  img {
    width: 100%;
    height: auto;
  }

  @media ${theme.breakpoints.pc} {
    height: 310px;
    width: 310px;

    margin-bottom: 80px;
  }
`;

const MenuButtonWrapper = styled(Box)``;

const MugiMenuButton = styled(Button)`
  font-family: "${theme.fonts.primary}";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 8.04px 23.07px;
  
  color: ${theme.colors.black};
  border-color: ${theme.colors.black};
  border-radius: 0;

  :hover {
    background-color: ${theme.colors.bgPrimary};
    border-color: ${theme.colors.black};

  }

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-heignt: 36px;
    padding: 11.5px 33px;
  }
`;

const BakuMenuButton = styled(Button)`
  font-family: "${theme.fonts.primary}";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 8.04px 23.07px;
  
  color: ${theme.colors.white};
  border-color: ${theme.colors.white};
  border-radius: 0;


  :hover {
    background-color: ${theme.colors.blackText};
    border-color: ${theme.colors.white};
  }

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-heignt: 36px;
    padding: 11.5px 33px;
  }
`;

const AboutContainer = styled(Box)`
  margin: 22px 23px 40px;

  div:last-of-type > p:last-of-type {
    margin-bottom: 0px;
  }

  @media ${theme.breakpoints.pc} {
    margin: 73px auto 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 872px;
  }
`;

const AboutText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;

  margin-bottom: 24px;

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;

    margin-bottom: 56px;
  }
`;

const AboutContact = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;

  margin-bottom: 24px;
  text-align: center;

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;

    margin-bottom: 56px;
  }
`;

const AboutTitle = styled(Text)`
  font-size: 18px;
  font-weight: 300;
  line-height: 34px;
  text-align: center;
  padding-bottom: 4px;
  margin-bottom: 18px;

  position: relative;

  ::before {
    border-bottom: solid 2px ${theme.colors.underlineBlack};
    position: absolute;
    content: '';
    bottom: 0;
    width: 40%;
    left: 30%;
  }

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 48px;

    ::before {
      width: 24%;
      left: 38%;
    }
  }
`;

const CalendarContainer = styled(Text)`
  text-align: center;
  position: relative;
`;

const CalendarButton = styled(Button)`
  font-family: "${theme.fonts.primary}";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 57px;
  margin-bottom: 57px;
  
  color: ${theme.colors.blackText};
  border-color: ${theme.colors.mugiDivider};
  border-radius: 0;

  @media ${theme.breakpoints.pc} {
    font-size: 24px;
    line-heignt: 36px;
    padding: 11.5px 33px;
    text-align: center; 
    align-items: center;
    justify-content: center;
  }

  :hover {
    border-color: ${theme.colors.mugiDivider};
  }
`;

const Component = ({}: Props): React.ReactElement => {
  const scroller = Scroll.scroller;

  useEffect(() => {
    if (window.location.hash == '#map') {
      scroller.scrollTo('map', {
        duration: 1000,
        delay: 100,
        smooth: true,
      });
    }
  });

  return (
    <Top>
      <Stack>
        <MenuContainer>
          <MugiMenuContainer>
            <MenuImageWrapper>
              <img src={MugiLogo} />
            </MenuImageWrapper>
            <MenuButtonWrapper>
              <MugiMenuButton variant="outlined" href="/mugi">
                Menu
              </MugiMenuButton>
            </MenuButtonWrapper>
          </MugiMenuContainer>

          <BakuMenuContainer>
            <MenuImageWrapper>
              <img src={BakuLogo} />
            </MenuImageWrapper>
            <MenuButtonWrapper>
              <BakuMenuButton variant="outlined" href="/baku">
                Menu
              </BakuMenuButton>
            </MenuButtonWrapper>
          </BakuMenuContainer>
        </MenuContainer>

        <AboutContainer>
          <CalendarContainer>
            <CalendarButton variant="outlined" href="/calendar">
              営業カレンダー
            </CalendarButton>
          </CalendarContainer>

          <div>
            <AboutTitle>mugiについて</AboutTitle>
            <AboutText>
              国内・海外のブルワリーから店主が厳選したクラフトビールを全9タップお楽しみいただけます。
            </AboutText>
            <AboutContact>
              <p>ご予約／お問い合わせ</p>
              <p>TEL:082-909-2489</p>
            </AboutContact>
          </div>

          <div>
            <AboutTitle>BAKUについて</AboutTitle>
            <AboutText>
              あつあつ鉄板焼きと広島の名物であるお好み焼きを熟練の職人が美味しく焼き上げます。
            </AboutText>
            <AboutContact>
              <p>ご予約／お問い合わせ</p>
              <p>TEL:082-909-2490</p>
            </AboutContact>
          </div>
        </AboutContainer>
        <Map />
      </Stack>
    </Top>
  );
};

export default Component;
